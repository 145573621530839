import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import PostLink from '../components/PostLink';

export default class Blog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isHover: false
    }
  }

  render() {
    const styles = {
      blogTitle: {
        fontSize: '2rem'
      },
      postTitle: {
        marginBottom: 0
      },
      postDate: {
        fontSize: 14,
        letterSpacing: 2,
        marginTop: 8,
        marginBottom: 12
      }
    }

    return (
      <Layout metadata={this.props.data.site.siteMetadata} currentPage="Blog">
        <div>
          <h1 style={ styles.blogTitle}>Blog</h1>
          <h4>{this.props.data.allMarkdownRemark.totalCount} Posts</h4>
          <div>
          {this.props.data.allMarkdownRemark.edges.map(({node}) => (
            <div key={node.id}>
              <PostLink to={node.frontmatter.path}>
                <h1 style={styles.postTitle}>{node.frontmatter.title}</h1>
              </PostLink>
              <div style={styles.postDate}>{node.frontmatter.date}</div>
              <p>{node.excerpt}</p>
            </div>
          ))}
          </div>
        </div>
      </Layout>
    )
  }
}

export const query = graphql `
  query IndexQuery {
    allMarkdownRemark(sort: {fields: [frontmatter___date], order: DESC}) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "DD MMMM, YYYY")
            path
          }
          excerpt(pruneLength: 250)
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`;